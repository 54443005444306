import fetchProductNames from "./roulette.js";

// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
  "use strict";

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll(".needs-validation");
  const form_container = document.getElementById("form_container");
  const roulette_container = document.getElementById("roulette_container");
  const subtitlesFirst = document.querySelector(".subtitlesFirst");
  const subtitlesSecond = document.querySelector(".subtitlesSecond");

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms).forEach(function (form) {
    form.addEventListener(
      "submit",
      function (event) {
        event.preventDefault();

        if (!form.checkValidity()) {
          event.stopPropagation();
        } else {
          // const cityName = submitData(csrftoken);
          const formData = submitData(csrftoken);
          form_container.classList.add("d-none");
          subtitlesFirst.classList.add("d-none");
          subtitlesSecond.classList.remove("d-none");
          roulette_container.classList.remove("d-none");

          fetchProductNames(formData);
        }

        form.classList.add("was-validated");
      },
      false
    );
  });
})();

const submitData = (csrftoken) => {
  const firstName = $("#firstName").val();
  const lastName = $("#lastName").val();
  const email = $("#email").val();
  const mobile = $("#mobile").val();
  const city = $("#city").val();

  // Create an object with the form data
  const formData = {
    name: firstName,
    surname: lastName,
    email: email,
    mobile: mobile,
    city: city,
  };
  // Replace 'your-api-endpoint' with your actual API endpoint
  // const apiUrl = envHost + "api/addPlayer";

  // // Send a POST request to the API endpoint using jQuery
  // $.ajax({
  //   type: "POST",
  //   url: apiUrl,
  //   data: JSON.stringify(formData),
  //   contentType: "application/json",
  //   headers: { "X-CSRFToken": csrftoken },
  //   success: function (response) {
  //     // Handle the success response from the API
  //     // console.log(response);
  //   },
  //   error: function (error) {
  //     // Handle any errors that occur during the request
  //     console.error("Error:", error);
  //   },
  // });

  return formData;
};
