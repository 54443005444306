// game controls
const modal = $("#exampleModal");
const new_game = $("#new_game");

new_game.on("click", () => {
  location.reload();
});

// Roulette part

let options;
let GlobalcityName;
let winningIndex;
let formData;

function noProducts() {
  const subtitle = $("#subtitle");
  const nogift = $("#no_gift");
  const roulette_container = $("#roulette_container");

  subtitle.addClass("d-none");
  nogift.removeClass("d-none");
  roulette_container.addClass("d-none");
}

function selectWinningItem(options) {
  var totalProbability = 0;

  // Calculate the total probability
  for (var i = 0; i < options.length; i++) {
    totalProbability += options[i].probability;
  }

  // Calculate scaling factor
  var scalingFactor = 100 / totalProbability;

  // Generate a random number
  var random = Math.random() * 100; // Scale to 0-100 range

  // Select the winning item based on the scaled probabilities
  var cumulativeProbability = 0;
  for (var j = 0; j < options.length; j++) {
    cumulativeProbability += options[j].probability * scalingFactor;
    if (random <= cumulativeProbability) {
      return j;
    }
  }

  // If no item is selected, return last index
  return options.length - 1;
}

async function fetchProductNames(formDataVar) {
  formData = formDataVar;
  GlobalcityName = formData.city;
  try {
    const response = await fetch(envHost + "api/product/" + GlobalcityName);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    options = data;

    // Extract product names from the response data
    const productNames = data.filter((product) => {
      return product.product_quantity > 0;
    });

    // Sort the product names alphabetically
    if (productNames.length) {
      options = productNames.map((product) => {
        return {
          id: product.id,
          name: product.product_name,
          img: "/" + product.product_img,
          probability: product.probability,
        };
      });
      main(options);
    } else {
      noProducts();
    }
  } catch (error) {
    noProducts();
    console.error("Error:", error);
    return [];
  }
}

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const submitPlayer = (formData) => {
  const csrftoken = getCookie("csrftoken");

  const apiUrl = envHost + "api/addPlayer";
  console.log(apiUrl)

  // Send a POST request to the API endpoint using jQuery
  $.ajax({
    type: "POST",
    url: apiUrl,
    data: JSON.stringify(formData),
    contentType: "application/json",
    headers: { "X-CSRFToken": csrftoken },
    success: function (response) {
      // Handle the success response from the API
      // console.log(response);
    },
    error: function (error) {
      // Handle any errors that occur during the request
      console.error("Error:", error);
    },
  });

  return true
};

async function subtractProductQuantity(winningIndex) {
  let productId = options[winningIndex].id;
  const csrftoken = getCookie("csrftoken");

  try {
    const url =
      envHost +
      `api/Product` +
      GlobalcityName +
      `/${productId}/subtract_quantity/`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    });

    formData["prize"] = options[winningIndex].name;

    submitPlayer(formData);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
  } catch (error) {
    console.error("Error:", error);
  }
}

function main(options) {
  var startAngle = 0;
  var arc = Math.PI / (options.length / 2);
  var spinTimeout = null;

  var spinArcStart = 10;
  var spinTime = 0;
  var spinTimeTotal = 0;

  var ctx;

  document.getElementById("spin").addEventListener("click", (event) => {
    winningIndex = selectWinningItem(options);
    let spins = 0;
    if (options.length - options.length / Math.PI >= winningIndex) {
      spins =
        360 * 10 +
        ((options.length - options.length / Math.PI - winningIndex) * 360) /
          options.length;
    } else {
      spins =
        360 * 10 -
        ((winningIndex - (options.length - options.length / Math.PI)) * 360) /
          options.length;
    }
    spin(spins);
    event.target.disabled = true;
  });

  function byte2Hex(n) {
    var nybHexString = "0123456789ABCDEF";
    return (
      String(nybHexString.substr((n >> 4) & 0x0f, 1)) +
      nybHexString.substr(n & 0x0f, 1)
    );
  }

  function RGB2Color(r, g, b) {
    return "#" + byte2Hex(r) + byte2Hex(g) + byte2Hex(b);
  }

  function getColor(id) {
    switch (id) {
      case 0:
        return RGB2Color(140, 116, 87); // mustard
      case 1:
        return RGB2Color(136, 80, 54); // teal
      case 2:
        return RGB2Color(67, 57, 45); // purple
      case 3:
        return RGB2Color(186, 131, 103); // yellow
      case 4:
        return RGB2Color(216, 185, 157); // orange
      case 5:
        return RGB2Color(144, 250, 255); // light teal
      case 6:
        return RGB2Color(169, 201, 32); // light green
      case 7:
        return RGB2Color(232, 61, 79); // dark pink
      case 8:
        return RGB2Color(255, 140, 0); // dark orange
      case 9:
        return RGB2Color(225, 66, 145); // light pink
      default:
        return RGB2Color(0, 0, 0); // default to black for unknown color
    }
  }

  function drawRouletteWheel() {
    var canvas = document.getElementById("canvas");
    if (canvas.getContext) {
      var outsideRadius = 60;
      var textRadius = 240;
      var insideRadius = 360;

      ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, 500, 500);

      // Draw the middle black circle
      ctx.beginPath();
      ctx.arc(400, 400, outsideRadius, 0, 2 * Math.PI, false);
      ctx.fillStyle = "black";
      ctx.fill();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;
      ctx.stroke();

      ctx.strokeStyle = "light black";
      ctx.lineWidth = 1;

      ctx.font = "bold 20px Helvetica, Arial";

      for (var i = 0; i < options.length; i++) {
        var angle = startAngle + i * arc;
        //ctx.fillStyle = colors[i];
        ctx.fillStyle = getColor(i);

        ctx.beginPath();
        ctx.arc(400, 400, outsideRadius, angle, angle + arc, false);
        ctx.arc(400, 400, insideRadius, angle + arc, angle, true);
        ctx.stroke();
        ctx.fill();

        ctx.save();
        ctx.shadowOffsetX = -1;
        ctx.shadowOffsetY = -1;
        ctx.shadowBlur = 0;
        ctx.shadowColor = "white";
        ctx.fillStyle = "white";
        ctx.translate(
          400 + Math.cos(angle + arc / 2) * textRadius * 0.8,
          400 + Math.sin(angle + arc / 2) * textRadius * 0.8
        );
        ctx.rotate(angle + Math.PI / options.length);
        var text = options[i].name !== "Bonne chance pour une autre fois!" ? options[i].name : `bonne chance pour \n une autre fois`;
        var lines = text.split("\n");
        for (var j = 0; j < lines.length; j++) {
          ctx.fillText(lines[j], -ctx.measureText(lines[j]).width / 2, j * 20);
        }
        ctx.restore();
      }
    }
  }

  function spin(degrees) {
    var targetAngle = (degrees * Math.PI) / 180; // Convert degrees to radians
    spinTime = 0;
    spinTimeTotal = Math.random() * 3 + 5000;
    rotateWheel(targetAngle);
  }

  function rotateWheel(targetAngle) {
    spinTime += 40;
    if (spinTime >= spinTimeTotal) {
      stopRotateWheel();
      return;
    }
    var deltaAngle = targetAngle - startAngle;
    if (deltaAngle < 0) deltaAngle += Math.PI * 2; // Ensuring rotation in the correct direction
    var spinAngle = deltaAngle * easeOut(spinTime, 0, 1, spinTimeTotal);
    startAngle += spinAngle;
    drawRouletteWheel();
    spinTimeout = setTimeout(function () {
      rotateWheel(targetAngle);
    }, 30);
  }

  function stopRotateWheel() {
    clearTimeout(spinTimeout);
    var degrees = (startAngle * 180) / Math.PI;
    var arcd = (arc * 180) / Math.PI;
    var index = Math.floor((360 - (degrees % 360)) / arcd);
    ctx.save();
    ctx.font = "bold 30px Helvetica, Arial";
    var text = options[winningIndex].name;
    showPrize(text, winningIndex);
    subtractProductQuantity(winningIndex);
    ctx.restore();
  }

  function showPrize(prize, winningIndex) {
    const modal_body = $("#modal_body");
    const confetti = $("#confetti");
    let text;

    if (winningIndex === 4) {
      document.querySelector(".modal-title").remove();
      text = `<p class="text-center text-white"><br> <span style="font-weight:bold;" class="text-white">${prize}</span></p>`;
    } else {
      text = `<p class="text-center text-white">Vous avez gagné : <br> <span style="font-weight:bold;" class="text-white">${prize}</span></p>`;
    }

    $(text).appendTo(modal_body);
    modal.modal("show");
    confetti.removeClass("d-none");
  }

  function easeOut(t, b, c, d) {
    // This easing function will ensure the animation duration is fixed at 5 seconds
    t /= d;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  drawRouletteWheel();
}

export default fetchProductNames;
